import { downloadZip } from "@/api/user";

const zip = async (dir: string, name: string, filename: string, type: string): Promise<void> => {
    const res = await downloadZip({ dir, name })
    const content = res.data
    const blob = new Blob([content], {
        // 下载的文件格式自己在这边更改type的值就好了
        type
    })
    if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = filename
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
    }
}

export default {
    zip
}